<!--
 * @Description: 营业汇总
 * @Author: 琢磨先生
 * @Date: 2022-10-08 10:27:04
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-02-19 23:05:46
-->
<template>
  <div v-loading="loading">
    <div class="query">
      <div class="picker_box">
        <shop-select class="shop_select" @change="shopChange" ></shop-select>
        <el-select
          v-model="query.ota_id"
          class="revenue_ota_select"
          placeholder="选择渠道"
          clearable
          @change="otaChange"
        >
          <el-option :label="item.name" :value="item.id" v-for="item in ota_list" :key="item.id"></el-option>
        </el-select>
        <ul>
          <li
            :class="i === dateIndex ? 'active' : ''"
            v-for="(item, i) in shortcuts"
            :key="item"
            @click="onDateLiTab(i)"
          >{{ item }}</li>
        </ul>
        <el-date-picker
          class="revenue_date_picker"
          v-model="query.dates"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
          type="daterange"
          unlink-panels
          @change="pickerChange"
          :clearable="false"
          start-placeholder
          end-placeholder
        />
      </div>
    </div>
    <div class="text-16-bold mt-20">营业额概况</div>
    <el-card class="box">
      <div class="statistics">
        <div class="col total">
          <div class="icon"></div>
          <div class="data">
            <div class="label">
              总营业额
              <el-tooltip
                class="box-item"
                effect="dark"
                content="总营业额 = 房费 + 违约金 + 消费 - 平台管理费"
                placement="top"
              >
                <el-icon>
                  <Warning />
                </el-icon>
              </el-tooltip>
            </div>
            <div class="amount">￥{{ total_model.total.decimal_amount }}</div>
            <div class="percent"></div>
          </div>
        </div>
        <div class="col">
          <div class="icon"></div>
          <div class="data">
            <div class="label">房费</div>
            <div class="amount">￥{{ total_model.room_fee.decimal_amount }}</div>
            <div class="percent">占比 {{ total_model.room_fee.percentage }}%</div>
          </div>
        </div>
        <div class="col">
          <div class="icon"></div>
          <div class="data">
            <div class="label">违约金</div>
            <div class="amount">￥{{ total_model.penal_sum.decimal_amount }}</div>
            <div class="percent">占比 {{ total_model.penal_sum.percentage }}%</div>
          </div>
        </div>
        <div class="col">
          <div class="icon"></div>
          <div class="data">
            <div class="label">客房消费</div>
            <div class="amount">￥{{ total_model.consume.decimal_amount }}</div>
            <div class="percent">占比 {{ total_model.consume.percentage }}%</div>
          </div>
        </div>
        <div class="col">
          <div class="icon"></div>
          <div class="data">
            <div class="label">管理费</div>
            <div class="amount">￥{{ total_model.mgr_fee_sum.decimal_amount }}</div>
            <div class="percent">占比 {{ total_model.mgr_fee_sum.percentage }}%</div>
          </div>
        </div>
      </div>
    </el-card>
    <el-row :gutter="20" style="margin-top: 20px">
      <el-col :span="9" :offset="0">
        <div class="block chart">
          <div class="header">营业汇总统计</div>
          <div class="body">
            <gather-pie :model="total_model"></gather-pie>
          </div>
        </div>
      </el-col>
      <el-col :span="15" :offset="0">
        <div class="block chart">
          <div class="header">每日营业统计</div>
          <div class="body">
            <everyday-line :arg="query"></everyday-line>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import date_util from "@/utils/date_util";
import GatherPie from "./gather_pie.vue";
import EverydayLine from "./everyday_line_chart.vue";
import ota_api from "@/http/ota_api";
import ShopSelect from "@/views/components/shop_select.vue";

export default {
  components: {
    GatherPie,
    EverydayLine,
    ShopSelect,
  },
  data() {
    return {
      dateIndex: "",
      loading: false,
      // dateList: [],
      shortcuts: ["昨日", "今日", "上周", "本周", "上月", "本月"],
      //总计对象
      total_model: {
        consume: { decimal_amount: 0, percentage: 0 },
        room_fee: { decimal_amount: 0, percentage: 0 },
        total: { decimal_amount: 0, percentage: 0 },
        penal_sum: { decimal_amount: 0, percentage: 0 },
        mgr_fee_sum: { decimal_amount: 0, percentage: 0 },
      },
      //查询参数
      query: {
        ota_id: "",
      },
      ota_list: [],
    };
  },
  created() {
    ota_api.get_otas().then((res) => {
      if (res.code == 0) {
        this.ota_list = res.data;
      }
    });
    this.onDateLiTab(5);
  },
  methods: {
    /**
     * 快速日期选择切换
     */
    onDateLiTab(i) {
      this.dateIndex = i;
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth();
      var day = date.getDate();
      date = new Date(year, month, day);
      //默认当前月
      var start = new Date(year, month, 1);
      var end = new Date(year, month, day);
      var weekday = date.getDay();
      if (i == 0) {
        //昨日
        start = new Date(date.getTime() - 3600 * 24 * 1000);
        end = start;
      }
      if (i == 1) {
        //今日
        start = end;
      }
      if (i == 2) {
        //上周
        start = new Date(new Date(date).setDate(date.getDate() - weekday - 6));
        end = new Date(new Date(date).setDate(date.getDate() - weekday));
      }
      if (i == 3) {
        //本周
        start = new Date(new Date(date).setDate(date.getDate() - weekday + 1));
      }
      if (i == 4) {
        //上个月
        var x = new Date(new Date(start).setMonth(start.getMonth() - 1));
        end = new Date(new Date(start).setDate(new Date(start).getDate() - 1));
        start = x;
      }

      this.query.dates = [
        date_util.formatDate(start),
        date_util.formatDate(end),
      ];
      this.loadData();
    },

    /**
     * 日期选择更改
     */
    pickerChange() {
      this.dateIndex = "";
      this.loadData();
    },

    /**
     * 渠道选择
     */
    otaChange() {
      this.loadData();
    },

    /**
     * 门店选择
     * @param {*} val
     */
    shopChange(val) {
      this.query.shop_id = val;
      this.loadData();
    },

    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.query = Object.assign({}, this.query);
      this.query.start_date = this.query.dates[0];
      this.query.end_date = this.query.dates[1];
      this.$http
        .post("seller/v1/analysis/gather/statistics", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.total_model = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style   scoped>
.text-16-bold {
  font-size: 16px;
  font-weight: 700;
}
.mt-20 {
  margin-top: 20px;
}
.query {
  display: flex;
  align-items: center;
}
.picker_box {
  display: flex;
  background: white;
  align-items: center;
  border-radius: var(--el-border-radius-base);
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color));
  overflow: hidden;
  height: 32px;
}
.picker_box ul {
  list-style: none;
  display: flex;
  align-items: center;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  height: 100%;
  border-right: 1px solid var(--el-border-color);
}
.picker_box ul li {
  list-style: none;
  padding: 0 10px;
  cursor: pointer;
}
.picker_box ul li:hover {
  color: #409eff;
}
.picker_box ul li.active {
  font-weight: 600;
  color: #409eff;
}

.statistics {
  display: flex;
  align-items: center;
}

/* 概览数据 */
.statistics .col {
  display: flex;
  align-items: center;
  height: 90px;
  position: relative;
  width: 19%;
  padding-left: 20px;
}
.statistics .col::after {
  position: absolute;
  content: "";
  display: block;
  width: 1px;
  background: var(--el-border-color);
  height: 40px;
  right: 0;
}
.statistics .col:last-child:after {
  background: none;
}
.statistics .col.total {
  flex-grow: 1;
}
.statistics .col .data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.statistics .col .data .label {
  color: #999;
  display: flex;
  align-items: center;
}
.statistics .col .data .label .el-icon {
  margin-left: 5px;
}
.statistics .col .data .amount {
  font-size: 26px;
  font-weight: 700;
}
.statistics .col .data .percent {
  font-size: 12px;
  color: #999;
}

.block .header {
  padding: 20px 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}

/* 图表 */
.block.chart .body {
  background: white;
  border-radius: 5px;
  height: 420px;
}
.block.chart .body .pie {
  height: 100%;
}
</style>
<style>
.revenue_date_picker.el-input__wrapper,
.revenue_ota_select .el-input__wrapper,
.shop_select .el-input__wrapper {
  box-shadow: none !important;
  border-radius: 0;
}
</style>